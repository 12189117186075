import React from 'react';
import Loadable from 'react-loadable';
import SectionPlatform from '../partials/_SectionPlatform';
import {
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import formModifierPreviewData from '../../../../data/previews/form-modifier.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const FormModifier = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.FormModifier;
    return <Component {...props} />;
  },
});

const Input = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Input;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Form Modifier"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Forms"
        tierThree="Form Modifier"
      />
      <V5Notice
        componentName="Form Modifier"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-form-modifier--docs"
      />

      <PageNavigation links={['Density', 'Size', 'Usage', 'Platform']} />

      <Section title="Density">
        <Paragraph>
          More often than not, a form consists of many fields. When that
          happens, we need to show spacing some love. Choose one of two
          available styles to make those breaks consistent: standard or compact.
        </Paragraph>
        <ComponentPreview
          name="FormDensity"
          previewData={formModifierPreviewData.density}>
          <FormModifier>
            <Input type="text" label="Street Address" />
            <Input type="text" label="State" />
            <Input type="text" label="Zip Code" />
          </FormModifier>
        </ComponentPreview>
      </Section>

      <Section title="Size">
        <Paragraph>
          Consider the number of form fields and interface density when choosing
          between the four available sizes.
        </Paragraph>
        <ComponentPreview
          name="FormSize"
          previewData={formModifierPreviewData.size}>
          <FormModifier>
            <Input type="text" label="Street Address" />
            <Input type="text" label="State" />
            <Input type="text" label="Zip Code" />
          </FormModifier>
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Buttons</SectionSubhead>
        <Paragraph>
          If a button sits on the same row as a form field, the sizes should
          match.
        </Paragraph>
        <Paragraph>
          If the button has a row all its own—like a single submit—use a large,{' '}
          <Link href="/components/buttons/button/design">primary style</Link> to
          make the CTA more clear.
        </Paragraph>
        <DontDo
          dontText="mix button and form field sizes for the sake of an obvious CTA"
          doText="match the button size with the related form fields."
          imgFilename="formmodifier-button"
        />
      </Section>

      <SectionPlatform />
    </PageWithSubNav>
  );
};

export default IndexPage;
